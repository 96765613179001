let qo = function ($) {

    let $body = $('body');

    /**
     * onChange
     */
    let textOnChange = function () {
        $body.on('keyup', '#module .qo textarea', function () {
            let $next = $body.find('#module > section.main > .content > .next');
            let $btnAnswers = $body.find('#module .qo .answers a');

            if ($(this).val().length !== 0) {
                $next.removeClass('disabled');
                $btnAnswers.removeClass('disabled');
            } else {
                $next.addClass('disabled');
                $btnAnswers.addClass('disabled');
            }
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.qo .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            if ($(_this).hasClass('disabled')) return false;

            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });

        });
    };

    return {
        init: function () {
            textOnChange();
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    qo.init();
});